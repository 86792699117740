export enum ResponseType {
    SUCCESS = "success",
    ERROR = "error",
  }

interface SuccessResponse<T> {
    status: ResponseType.SUCCESS;
    data: T;
  }
  
  interface ErrorResponse {
    status: ResponseType.ERROR;
    error: string;
  }
  
  export type ActionResponse<T> = SuccessResponse<T> | ErrorResponse;