
"use client"

import { addInvoiceInformation } from "@/actions/team";
import { Button } from "@/components/ui/button";
import { AddInvoiceInformationSchema } from "@/schemas";
import { zodResolver } from "@hookform/resolvers/zod";
import { Country, InvoiceInformation } from "@prisma/client";
import { useState, useTransition } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
} from "@/components/ui/command"
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover"
import { Check, ChevronsUpDown, LoaderCircle, Plus } from "lucide-react";
import { ScrollArea } from "@/components/ui/scroll-area";
import { capitalizeFirstLetter, cn } from "@/lib/utils";
import { ResponseType } from "@/types/response-types";
import { FormError } from "@/components/form-error";

interface InvoiceInformationFormProps {
    className?: string;
    teamId: number;
    countries: Country[];
    invoiceInformationAdded: (invoiceInformation: InvoiceInformation) => void;
}

export const InvoiceInformationForm = ({ className, teamId, countries, invoiceInformationAdded }: InvoiceInformationFormProps) => {
    const [error, setError] = useState<string | undefined>("");
    const [isPending, startTransition] = useTransition();

    const [open, setOpen] = useState(false)
    const [value, setValue] = useState("")

    const form = useForm<z.infer<typeof AddInvoiceInformationSchema>>({
        resolver: zodResolver(AddInvoiceInformationSchema),
        defaultValues: {
            companyName: "",
            companyVatNumber: "",
            companyAddress: "",
            companyCity: "",
            companyPostalCode: "",
            companyCountryName: undefined
        }
    });

    const onSubmit = (values: z.infer<typeof AddInvoiceInformationSchema>) => {

        startTransition(() => {
            addInvoiceInformation(teamId, values)
                .then((response) => {
                    if (response.status === ResponseType.ERROR) {
                        setError(response.error);
                    } else {
                        invoiceInformationAdded(response.data);
                        form.reset();
                    }
                })
        });
    }

    return (
        <Form {...form}>
            <form
                onSubmit={form.handleSubmit(onSubmit)}
                className={cn("gap-6 w-full grid grid-cols-2", className)}>
                <div className="flex flex-col w-full col-span-2 gap-2">
                    <FormLabel
                        htmlFor="companyName"
                        className=" text-neutral-900 text-xs font-bold font-roboto leading-3">
                        Company Name*
                    </FormLabel>
                    <FormField
                        control={form.control}
                        name="companyName"
                        render={({ field }) => (
                            <FormItem className="flex-1 w-full">
                                <FormControl>
                                    <Input
                                        placeholder="Company Name" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )} />
                </div>
                <p className="col-span-2 text-neutral-900 text-xs font-bold font-roboto leading-3">
                    Company Address
                </p>
                <div className="flex flex-col w-full col-span-1 gap-2">
                    <FormLabel
                        htmlFor="companyAddress"
                        className=" text-neutral-900 text-xs font-roboto leading-3">
                        Street
                    </FormLabel>
                    <FormField
                        control={form.control}
                        name="companyAddress"
                        render={({ field }) => (
                            <FormItem className="flex-1 w-full">
                                <FormControl>
                                    <Input
                                        placeholder="Address" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )} />
                </div>
                <div className="flex flex-col w-full col-span-1 gap-2">
                    <FormLabel
                        htmlFor="companyCity"
                        className=" text-neutral-900 text-xs font-roboto leading-3">
                        City
                    </FormLabel>
                    <FormField
                        control={form.control}
                        name="companyCity"
                        render={({ field }) => (
                            <FormItem className="flex-1 w-full">
                                <FormControl>
                                    <Input
                                        type="text"
                                        placeholder="City" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )} />
                </div>
                <div className="flex flex-col w-full col-span-1 gap-2">
                    <FormLabel
                        htmlFor="companyCountryName"
                        className=" text-neutral-900 text-xs font-roboto leading-3">
                        Company Name
                    </FormLabel>
                    <FormField
                        control={form.control}
                        name="companyCountryName"
                        render={({ field }) => (
                            <FormItem className="w-full">
                                <Popover open={open} onOpenChange={setOpen}>
                                    <PopoverTrigger asChild>
                                        <FormControl>
                                            <Button
                                                variant="darkFilled"
                                                role="combobox"
                                                aria-expanded={open}
                                                className="w-full h-12 justify-between bg-transparent text-black hover:bg-neutral-200 border-border border"
                                            >
                                                {value
                                                    ? countries.find((country) => country.displayName.toLowerCase() === value)?.displayName
                                                    : "Company Country"}
                                                <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                                            </Button>
                                        </FormControl>
                                    </PopoverTrigger>
                                    <PopoverContent className="w-full min-w-[360px] p-0">
                                        <Command
                                            className="w-full bg-neutral-100 text-background-900"
                                            onValueChange={field.onChange}>
                                            <CommandInput
                                                className="w-full"
                                                placeholder="Search countries..." />
                                            <CommandEmpty>No country found.</CommandEmpty>
                                            <CommandGroup
                                                className="w-full h-[300px] my-2 text-background-900"
                                            >
                                                <ScrollArea className="h-[300px] w-full">
                                                    {countries.map((country) => (
                                                        <CommandItem
                                                            className="aria-selected:bg-gray-300 aria-selected:text-black"
                                                            key={country.displayName}
                                                            value={country.displayName}
                                                            onSelect={(currentValue) => {
                                                                setValue(currentValue === value ? "" : currentValue)
                                                                setOpen(false)
                                                                field.value = currentValue
                                                                field.onChange(currentValue)
                                                            }}
                                                        >
                                                            <Check
                                                                className={cn("h-4 w-4 mr-2", value !== country.displayName.toLowerCase() ? "hidden" : "")}
                                                                color="#121212"
                                                                strokeWidth={1.5}
                                                            />
                                                            {capitalizeFirstLetter(country.displayName)}
                                                        </CommandItem>
                                                    ))}
                                                </ScrollArea>
                                            </CommandGroup>
                                        </Command>
                                    </PopoverContent>
                                </Popover>
                                <FormMessage />
                            </FormItem>
                        )} />
                </div>
                <div className="flex flex-col w-full col-span-1 gap-2">
                    <FormLabel
                        htmlFor="companyCity"
                        className=" text-neutral-900 text-xs font-roboto leading-3">
                        Zip / Postal Code
                    </FormLabel>
                    <FormField
                        control={form.control}
                        name="companyPostalCode"
                        render={({ field }) => (
                            <FormItem className="flex-1 w-full">
                                <FormControl>
                                    <Input
                                        type="text"
                                        placeholder="Postal Code" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )} />
                </div>

                <div className="flex flex-col w-full col-span-2 gap-2 mt-2">
                    <FormLabel
                        htmlFor="companyVatNumber"
                        className=" text-neutral-900 text-xs font-bold font-roboto leading-3">
                        VAT Number
                    </FormLabel>
                    <FormField
                        control={form.control}
                        name="companyVatNumber"
                        render={({ field }) => (
                            <FormItem className="flex-1 w-full">
                                <FormControl>
                                    <Input
                                        type="text"
                                        placeholder="VAT Number" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )} />
                </div>



                <p className="col-span-2 text-sm text-neutral-500 pt-8 w-full">
                    By providing your invoice details, you allow CM Navigator A/S to invoice you for future payments in accordance with their terms.
                </p>
                <FormError message={error} />
                <div className="col-span-2 flex flex-row items-center justify-center">
                    <Button
                        className="w-full max-w-[200px]"
                        type="submit"
                        disabled={isPending}
                        variant={"lightFilled"}>
                        Save
                    </Button>

                </div>

            </form>
        </Form>
    )
}
